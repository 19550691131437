<template>
	<div id="landList">
		<page-land-list :is-shop="1"></page-land-list>
	</div>
</template>
<script>
	import pageLandList from '@/components/layout/land/page-land-list.vue'
	export default{
		components:{
			pageLandList
		},
	}
</script>

<style>
</style>
